import {API, backendUrl, staticUrl} from "./api";
import moment from "moment";
import langs from '../translates/en/langs.json'
import {ESubscriptionStatus, IAsset, IUploadStatus, IUser} from "./rest";
import {toast} from "react-toastify";

export const addZeroToTime = (time: number) => {
  return time < 10 ? `0${time}` : time
}

export const generatePassword = (length: number = 8) => {
  let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const thumb = (id: string|undefined, size: number) => {
  if (!id) return '';
  return `${staticUrl}/thumb/${size}/${id}.webp`;
};

export const thumbAvatar = (id: string|undefined, size: number) => {
  if (!id) return '';
  return `${staticUrl}/avatar/${size}/${id}.webp`;
};

export const formatDate = (date?: string): string => {
  if (!moment(date).isValid()) return date || ''
  return moment(date).toDate().toLocaleDateString();
};

export const getLanguageName = (ln: string): string => {
  //@ts-ignore
  const lang = langs[ln];
  if (!lang) return ln;
  return lang;
};

export const shuffleArr = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return [...array];
};

export const isSub = (user?: IUser|null): boolean => {
  if (!user) return false
  return user.subscription?.status === ESubscriptionStatus.Active;
};

export const isMobile = /iPhone|iPad|iPod|Android|ie|blackberry|fennec/i.test(navigator.userAgent.toLowerCase()) || 'ontouchstart' in window;
export const isSmallDevice = window.innerWidth < 992;

export const download = (file: IAsset, name?: string): void => {
  window.location.href = `${backendUrl}/assets/download/${file.id}${name ? `/${name}` : ''}`;
};

export const uploadFile = (
  file: File,
  updateStatus?: (status: IUploadStatus) => void,
  chunkSize: number = 512 * 1024,
  signal?: AbortSignal // Добавляем параметр для AbortSignal
): Promise<IAsset> => {

  return  new Promise((resolve, reject) => {
    const fileSize = file.size;
    if (fileSize < chunkSize) {
      const form = new FormData();
      form.append('file', file);
      form.append('name', file.name);
      API.Assets.uploadForm(form) // Передаем signal в запрос
        .then(resolve)
        .catch((error) => {
          if (error.name === 'AbortError') {
            reject(new Error('Upload canceled'));
          } else {
            reject(error);
          }
        });
    } else {
      let offset = 0;
      let id: string;
      const processNextChunk = () => {
        if(signal?.aborted)  {
          return reject(new Error('AbortError'));
        }
        const chunk = file.slice(offset, chunkSize + offset);
        const sendChunk = (chunk: Blob) => {
          const form = new FormData();
          form.append('id', id);
          form.append('chunk', chunk);
          API.Assets.chunk(form) // Передаем signal в запрос
            .then((res) => {
              if (updateStatus) updateStatus(res);
              if (res.asset) {
                resolve(res.asset);
              } else {
                offset += chunkSize;
                processNextChunk();
              }
            })
            .catch((error) => {
              if (error.name === 'AbortError') {
                reject(new Error('Upload canceled'));
              } else {
                // toast.warning(error.message + ', retry in 3 sec....');
                setTimeout(() => sendChunk(chunk), 3000);
              }
            });
        };
        sendChunk(chunk);
      };
      API.Assets.partial({
        fileSize,
        fileType: file.type,
        fileName: file.name,
      }) // Передаем signal в запрос
        .then((res) => {
          id = res.id;
          processNextChunk();
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            reject(new Error('Upload canceled'));
          } else {
            reject(error);
          }
        });
    }
  });
}



